<template>
  <div class="cameracards">       
    <br>
   <div v-if="loading">{{loadingMessage}}</div>
    <v-layout align-center justify-center row fill-height>
        <v-card style="maxWidth: 2000px; margin-bottom: 2em;" v-for="camera in finalCameras" :key="camera.id">
          <v-card-title disable-icon-rotate>
          
            {{camera.name}}
            <v-col cols="6"><em>{{camera.groupName}}</em></v-col>
          </v-card-title>
            <v-card
              class="mx-auto"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">{{ camera.name }}, {{camera.coordinates}}<span class="pl-4 pa-1 pb-2">View on <a :href="`https://www.google.com/maps/search/?api=1&query=${camera.coordinates}`" target="_blank">Google Maps</a></span></div>
                  
                  <v-list-item-title class="headline mb-1">{{ camera.name }}</v-list-item-title>
                  
                </v-list-item-content>
              </v-list-item>
              <v-list-item three-line v-if="camera.streams.length > 0" >
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">Streams</v-list-item-title>
                    <v-container>
                    <v-row>
                        <v-col class = "player" v-for="stream in camera.streams" :key="stream.id">
                          <videoPlayer 
                          :options="stream.fullStream">
                          </videoPlayer>
                       </v-col>
                  </v-row>
                  <v-row align-center>
                    <!--<v-col style="maxWidth: 250px;"> 
                        <edwinAIchart v-bind:camid=camera.id></edwinAIchart>
                    </v-col>-->
                    <v-col style="maxWidth: 400px;"> 
                        <histogram-chart v-bind:camid=camera.id></histogram-chart>
                      </v-col>
                    <v-col style="maxWidth: 400px;"> 
                        <histogram-summary v-bind:camid=camera.id></histogram-summary>
                      </v-col>
                  </v-row>
                </v-container>
                </v-list-item-content> 
              </v-list-item>
            </v-card>
        </v-card>
    </v-layout>
    <v-layout align-center justify-center row fill-height>
        <v-card outlined style="padding:1em 1em;" v-for="group in userGroups" :key="group.id">
         <div class="overline mb-4"><a :href="`/group/${ group.id }`">{{ group.name }}</a></div>
        </v-card>
    </v-layout>
   
  </div>
</template>

<script>
// @ is an alias to /src
import {HTTP} from '@/api/http-common';
import {videoPlayer} from 'vue-videojs7'

export default {
  data() {
    return{
      loading: true,
      loadingMessage: "loading ...",
      finalCameras: [],
      playerOptions: {
        // videojs and plugin options
        height: '360',
        autoplay: true,
        sources: {
          type: "application/x-mpegURL",
          src: "http://69.162.231.249:4040/stream/d471ec7b-771e-418c-87ab-cb1c003f5ad0/index.m3u8"
        }
      },
      generalURL: process.env.VUE_APP_PROXY_BASE_URL,
      streamURLdone: '',
      groupCameras: [],
      dismissedAlert: false,
      dismissedAlertId: '',
      selected: [],
      loggedInUser: [],
      userGroups : []
    }
  },
  created() {
    //this.getLoggedInUser();
    this.loadingMessage = "loading camera info...";
    this.getGroupCameras().then(() =>{
      let generalURL = this.generalURL
      let finalCameraStreams = this.groupCameras
      finalCameraStreams.forEach(function(camera){
        if(camera.streams.length){
          camera.streams.forEach(function(stream){
            let url = generalURL + stream.url
            stream.fullStream = {
              height: '360',
              autoplay: true,
              muted: true,
              preload: 'auto',
              sources: {
                type: "application/x-mpegURL",
                src: url
              }
            }
            //this.loadingMessage = "loading camera streams..."
            //console.log('NEW STREAM:',JSON.stringify(stream))
          })
        }

      })
      this.finalCameras = finalCameraStreams
      //console.log(finalCameraStreams)
      this.loading = false
    }).catch(e => {
      console.log(e)
    });
    this.getLoggedInUserGroups().then(() =>{
      let userGroups = this.loggedInUserGroups
      this.userGroups = userGroups
      this.loading = false
    }).catch(e => {
      console.log(e)
    });
  },
  methods: {
      getLoggedInUser: function(){
      //console.log('LOOK HERE: ' + this.$auth.user.email);
        if (this.$auth.user.email) {
          let apiResponse = HTTP.get('user/' + this.$auth.user.email)
          return apiResponse.then(response => {
          this.loggedInUser = response.data
          //console.log("loggedInUser: " + this.loggedInUser)
          let selectedFromStart = []
          let availGroups = []
          if(this.loggedInUser.groups.length > 0){
            this.loggedInUser.groups.forEach(function(group){
              availGroups.push(group)
              if(group.notification === 1){
                selectedFromStart.push(group)
              }
            })
          }
          this.selected = selectedFromStart
          this.availableGroups = availGroups
          //console.log('Get logged in user: ', JSON.stringify(this.loggedInUser))
        }).catch(error => {
          console.log(error)
          this.loggedInUser = [
          {
            id: 0,
            firstName: "firstname",
            lastName: "lastname",
            email: this.$auth.user.email,
            groups: [
              {
                id: 0,
                name: "group",
                notification: false
              }
            ]
          }
          ]
          //console.log(this.loggedInUser.lastName)
        })
          } else return
        
    },
    getGroupCameras: function(){
      return HTTP.get('/camera/list/group/'+this.$route.params.groupId ).then(response => {
        this.groupCameras = response.data
        //console.log('Get cameras for group: ', JSON.stringify(this.groupCameras))
      })
    },
    getLoggedInUserGroups: function(){
      return HTTP.get('/group/list/edwinnotification1@gmail.com').then(response => {
        this.loggedInUserGroups = response.data
        //console.log('Get groups for logged in user: ', JSON.stringify(this.loggedInUserGroups))
      })
    },
    getActiveAlerts: function(alerts, camera) {
      let counter = 0;
      alerts.forEach(function(alert){
        if(alert.alert_status !== 'dismissed'){
          counter++;
          HTTP.put('/camera', {
            "id": camera.id,
            "status": "alert"
          }).catch(function (error) {
            console.log(error);
          });
          //camera.status = 'alert'
        }
        if(alert.alert_type === 'High Temperature'){
          alert.color = 'yellow darken-3'
        }
        else if(alert.alert_type === 'Rapid Rise in Temperature'){
          alert.color = 'orange darken-3'
        }
        else if(alert.alert_type === 'Danger'){
          alert.color = 'red darken-2'
        }
        else{
          alert.color = '#2196F3'
        }
      })
      if(counter === 0){
        camera.status = 'good';
        HTTP.put('/camera', {
          "id": camera.id,
          "status": "good",
        }).catch(function (error) {
          console.log(error);
        });
      }
      return counter;
    },
    getAlertCount (alert) {
      if (this.selected.includes(alert)) {
        // Removing the alert
        this.selected.splice(this.selected.indexOf(alert), 1);
      } else {
        this.selected.push(alert);
      }
    },
    /*dismissAlert (alertId, cameraId, alertType) {
      //console.log('Alert to dismiss: ', alertId, " on camera ", cameraId, " with alert type of ", alertType)
      HTTP.put(`/alert`, {
        "id": alertId,
        "cameraId": cameraId,
        "status": "dismissed",
        "alertType": alertType

      }).then(response => {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    }*/
  },
  components: {
    videoPlayer
  }

}
</script>


<style scoped>
  .player {
    width: 480px;
  }
  .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  }
</style>