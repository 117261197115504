import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import {domain, clientId } from "./auth_config.json"
import { Auth0Plugin } from "./auth"

import histogramChart from './components/chart/histogramChart.vue'
import histogramSummary from './components/histogramSummary.vue'
import edwinAIchart from './components/edwinAIchart.vue'


Vue.config.productionTip = false

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})
Vue.use(require('vue-moment'))

Vue.component('histogram-chart', histogramChart)
Vue.component('histogram-summary', histogramSummary)
Vue.component('edwinAIchart', edwinAIchart)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
