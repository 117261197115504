 <template>
 <v-footer
    color="#000e29"
    padless
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        v-for="link in links"
        :key="link.route"
        color="white"
        text
        rounded
        class="my-2"
      >
        <v-btn :href=link.route text color="white">{{ link.text }}</v-btn>
      </v-btn>
    </v-row>
    <v-col
        class="text-center white--text"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>EDWINProject</strong>
      </v-col>
  </v-footer>
  </template>

  <script>
export default {
    data() {
        return {
            drawer: false,
            links: [
                //{ icon: 'videocam', text: 'Dashboard', route: '/Dashboard' },
                //{ icon: 'account_circle', text: 'My Profile', route: '/profile' },
                //{ icon: 'supervisor_account', text: 'My Team', route: '/team' },
                { icon: 'home', text: 'Home', route: '/' }
                //{ icon: 'input', text: 'Login', route: '/' },
            ]
        }
    },

    methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }
  }

}
</script>