<template>
  <div class="dashboard">
    <h1 class="display-3 text-center pa-8">Welcome to the EDWIN Project Homepage</h1>
     <v-layout align-center justify-center>
      <div style="max-width: 1500px; padding: 2em"
    >
    <img src="@/assets/EDWIN_logo_2.png" style="max-width: 500px" class="center"><br>
    
      <v-layout align-center justify-center>
        <v-btn v-if="$auth.isAuthenticated" href="/Dashboard" color="#fe6c29" class="white--text">Go To Dashboard</v-btn>
      </v-layout>         
    <br>
    <h2 class="display-2 text-center">Early Detection Wildfire Imaging Network</h2>
    <br><span style="padding: 30px" align="left"><p>The EDWIN project seeks to design, build, and operate a large-scale thermal imaging network to support local public safety needs for early detection of wildfires in high-risk areas. The ultimate goal of the project is to cover the entire Wasatch front with a network of thermal imaging cameras to continuously monitor the high-risk bench areas.</p>
    <p>Below, you may see the feeds of the thermal cameras currently installed and scanning the landscape.</p></span>
  </div>
  </v-layout>
   <div v-if="loading">{{loadingMessage}}</div>
    <v-row class="mx-auto" outlined style="padding:0.5em 0.5em; maxWidth: 1500px; text-align: center;" v-for="group in userGroups" :key="group.id">
        <div class="overline mb-4"><a :href="`/group/${ group.id }`">{{ group.name }}</a></div>
    </v-row>

  </div>
</template>

<script>
// @ is an alias to /src
import {HTTP} from '@/api/http-common';
//import {videoPlayer} from 'vue-videojs7'

export default {
  data() {
    return{
      loading: true,
      loadingMessage: "loading ...",
      generalURL: process.env.VUE_APP_PROXY_BASE_URL,
      loggedInUser: [],
      userGroups: []
    }
  },
  created() {
    //this.getLoggedInUser();
    this.loadingMessage = "loading camera sites...";
    this.getLoggedInUserGroups().then(() =>{
      //let generalURL = this.generalURL
      let userGroups = this.loggedInUserGroups
      this.userGroups = userGroups
      this.loading = false
    }).catch(e => {
      console.log(e)
    });
  },
  methods: {
      getLoggedInUser: function(){
      //console.log('LOOK HERE: ' + this.$auth.user.email);
        if (this.$auth.user.email) {
          let apiResponse = HTTP.get('user/' + this.$auth.user.email)
          return apiResponse.then(response => {
          this.loggedInUser = response.data
          //console.log("loggedInUser: " + this.loggedInUser)
          let selectedFromStart = []
          let availGroups = []
          if(this.loggedInUser.groups.length > 0){
            this.loggedInUser.groups.forEach(function(group){
              availGroups.push(group)
              if(group.notification === 1){
                selectedFromStart.push(group)
              }
            })
          }
          this.selected = selectedFromStart
          this.availableGroups = availGroups
          //console.log('Get logged in user: ', JSON.stringify(this.loggedInUser))
        }).catch(error => {
          console.log(error)
          this.loggedInUser = [
          {
            id: 0,
            firstName: "firstname",
            lastName: "lastname",
            email: this.$auth.user.email,
            groups: [
              {
                id: 0,
                name: "group",
                notification: false
              }
            ]
          }
          ]
          //console.log(this.loggedInUser.lastName)
        })
          } else return
        
    },
    getLoggedInUserGroups: function(){
      return HTTP.get('/group/list/edwinnotification1@gmail.com').then(response => {
        this.loggedInUserGroups = response.data
        //console.log('Get groups for logged in user: ', JSON.stringify(this.loggedInUserGroups))
      })
    },
  },

}
</script>


<style scoped>
  .player {
    width: 480px;
  }
  .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  }
</style>